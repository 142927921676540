import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { DesaturationSheetsPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useIElementClippingBoxObb } from "@/hooks/use-object-bounding-box";
import { useCached3DObjectsIfExists } from "@/object-cache";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import {
  IElementSection,
  isIElementAreaSection,
  isIElementImg360,
  isValid,
} from "@faro-lotv/ielement-types";
import { FloorPlanBackgroundTransparency } from "@faro-lotv/lotv";
import {
  selectChildDepthFirst,
  selectIElement,
  selectMainAreaVolume,
} from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "es-toolkit";
import { useEffect } from "react";
import { OrthographicCamera } from "three";
import { selectCurrentAreaData } from "../mode-selectors";
import { SheetWaypoints } from "../sheet-mode/sheet-waypoints";
import { useTagsManagementContext } from "./tags-management-context";
import {
  selectTagsManagementScene,
  TagsManagementScene as TagsManagementCurrentScene,
} from "./tags-management-selector";

/** @returns The 3D \scene of the tags management mode */
export function TagsManagementScene(): JSX.Element | null {
  const { activeAreaId } = useTagsManagementContext();
  const activeArea = useAppSelector(selectIElement(activeAreaId));
  assert(
    activeArea && isIElementAreaSection(activeArea),
    "There should always be an area active",
  );

  const areaData = useAppSelector(selectCurrentAreaData(activeArea), isEqual);
  const currentScene = useAppSelector(
    selectTagsManagementScene(areaData),
    isEqual,
  );

  const areaVolume = useAppSelector(selectMainAreaVolume(activeArea));
  const areaBox = useIElementClippingBoxObb(areaVolume);

  const panos = useAppSelector(
    (state) => selectScenePanos(state, currentScene),
    isEqual,
  );

  const cameraData = useCenterCameraOnPlaceholders({
    areaVolume: areaBox,
    sheetElements: currentScene.sheets,
    activeSheetFor2DNavigation: currentScene.sheets[0],
    placeholders: panos,
  });

  const camera = useThree((s) => s.camera);
  useEffect(() => {
    if (camera instanceof OrthographicCamera) {
      centerOrthoCamera(camera, cameraData);
    }
  }, [camera, cameraData]);

  const sheetObjects = useCached3DObjectsIfExists(currentScene.sheets);
  return (
    <>
      {sheetObjects.map((sheet) => (
        <SheetRenderer
          sheet={sheet}
          backgroundTransparent={FloorPlanBackgroundTransparency.Alpha}
          key={sheet.iElement.id}
        />
      ))}
      <SheetModeControls camera={camera} referencePlaneHeight={0} />
      <SheetWaypoints paths={[]} panos={panos} />
      <DesaturationSheetsPipeline enabled={false} transparentBackground />
    </>
  );
}

/* eslint-disable jsdoc/require-param */
/**
 * @returns The collection of iElementImg360 children of the current scene
 * @param currentScene The current scene of the tags management mode
 */
const selectScenePanos = createSelector(
  [
    (state: RootState) => state,
    (state: RootState, currentScene: TagsManagementCurrentScene) =>
      currentScene.rooms,
    (state: RootState, currentScene: TagsManagementCurrentScene) =>
      currentScene.scans,
  ],
  (
    state: RootState,
    rooms: IElementSection[],
    scans: Record<string, IElementSection[]>,
  ) =>
    [...rooms, ...Object.values(scans).flat()]
      .map((e) => selectChildDepthFirst(e, isIElementImg360)(state))
      .filter(isValid),
);
