import { ModalSpinner } from "@/components/ui/modal-spinner";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectAlignmentStepForCloudToSheetAlignment,
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
  selectLayoutForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import {
  CloudToSheetAlignmentStep,
  setCrossSectionEnabledForCloudToSheetAlignment,
  setLayoutForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import { neutral } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { CloudToSheetAlignmentProgressBar } from "./cloud-to-sheet-alignment-progreess-bar";
import { CloudToSheetAlignmentSplitScreen } from "./cloud-to-sheet-alignment-split-screen";

/** @returns The overlay for cloud-to-sheet alignment mode */
export function CloudToSheetAlignmentModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const [showSpinner, setShowSpinner] = useState(false);

  const applyAlignment = useCallback(() => {
    setShowSpinner(true);
    // after alignment force switch to 2D mode

    dispatch(changeMode("sheet"));
    setShowSpinner(false);
  }, [dispatch]);

  const step = useAppSelector(selectAlignmentStepForCloudToSheetAlignment);
  const alignmentViewLayout = useAppSelector(
    selectLayoutForCloudToSheetAlignment,
  );
  const changeAlignmentViewLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setLayoutForCloudToSheetAlignment(value)),
    [dispatch],
  );

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <CloudToSheetAlignmentProgressBar applyAlignment={applyAlignment} />

        {step === CloudToSheetAlignmentStep.setElevation && (
          <CloudToSheetAlignmentElevation />
        )}
        {step === CloudToSheetAlignmentStep.pairPoints && (
          <>
            {alignmentViewLayout === AlignmentViewLayout.splitScreen && (
              <CloudToSheetAlignmentSplitScreen />
            )}
            {alignmentViewLayout === AlignmentViewLayout.overlay && (
              <CloudToSheetAlignmentOverlayScreen />
            )}
            <AlignmentViewLayoutToggle
              alignmentLayout={alignmentViewLayout}
              changeAlignmentScreenLayout={changeAlignmentViewLayout}
            />
          </>
        )}
      </Stack>
    </>
  );
}

/** @returns The cloud-to-sheet alignment elevation sub-view */
function CloudToSheetAlignmentElevation(): JSX.Element {
  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          border: 3,
          borderColor: neutral[100],
          height: "100%",
        }}
      />
    </ViewDiv>
  );
}

/**
 * @returns cloud-to-sheet alignment overlay screen layout
 */
function CloudToSheetAlignmentOverlayScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );

  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CrossSectionToggleButton
        isEnabled={isCrossSectionEnabled}
        elevation={sheetElevation}
        onToggleCrossSections={() => {
          dispatch(
            setCrossSectionEnabledForCloudToSheetAlignment(
              !isCrossSectionEnabled,
            ),
          );
        }}
      />
    </ViewDiv>
  );
}
