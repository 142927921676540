import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
  EmbeddedToolbarVerticalDivider,
} from "@/components/ui/embedded-toolbar";
import { getCoordinateStringZUp } from "@/components/ui/scene-context-menu";
import { setPinMarker } from "@/store/pin-marker-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  DeleteIcon,
  FaroText,
  neutral,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { Tooltip } from "@mui/material";
import { MutableRefObject, useCallback, useMemo } from "react";
import { Vector3Tuple } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "../measurements/measure-constants";

type PinMarkerActionBarProp = {
  /** 3D position of the pin marker */
  pinPosition: Vector3Tuple;

  /** The parent that the toolbar should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;
};

/**
 * @returns Action toolbar for pin marker.
 */
export function PinMarkerActionBar({
  pinPosition,
  parentRef,
}: PinMarkerActionBarProp): JSX.Element | null {
  const { openToast } = useToast();

  const buttonSize = "40px";
  const actionBarPointOffsetX = "60px";
  const actionBarPointOffsetY = "40px";

  const dispatch = useAppDispatch();

  const deletePinMarker = useCallback(() => {
    dispatch(setPinMarker());
  }, [dispatch]);

  const coordinateString = useMemo(
    () => getCoordinateStringZUp(pinPosition),
    [pinPosition],
  );

  const copyCoordinates = useCallback((): void => {
    navigator.clipboard.writeText(coordinateString);
    openToast({ title: "Coordinates copied to clipboard" });
  }, [coordinateString, openToast]);

  return (
    <AppAwareHtml
      portal={parentRef}
      position={pinPosition}
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
      eps={-1}
      style={{
        transform: `translate(-${actionBarPointOffsetX}, calc(-100% - ${actionBarPointOffsetY}))`,
      }}
    >
      <EmbeddedToolbar
        isActive
        sx={{
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          padding: "0px",
          backgroundColor: neutral[999],
          borderRadius: "6px",
        }}
      >
        <Tooltip title="Copy coordinates" placement="top">
          <EmbeddedToolbarButton
            aria-label="copy coordinates"
            fullWidth
            value={coordinateString}
            onClick={copyCoordinates}
          >
            <FaroText variant="bodyM" color={neutral[0]}>
              {coordinateString}
            </FaroText>
          </EmbeddedToolbarButton>
        </Tooltip>
        <EmbeddedToolbarVerticalDivider />
        <Tooltip title="Delete" placement="top">
          <EmbeddedToolbarButton
            aria-label="delete pin marker"
            buttonSize={buttonSize}
            value="Delete"
            onClick={deletePinMarker}
          >
            <DeleteIcon />
          </EmbeddedToolbarButton>
        </Tooltip>
      </EmbeddedToolbar>
    </AppAwareHtml>
  );
}
