import { computeDeepLink } from "@/components/common/deep-link/deep-link-utils";
import { useFileUploaderWithPromise } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import {
  AnnotationCreationData,
  ExternalAnnotationData,
  SphereXGAnnotationData,
  createPostTopic,
  isExternalAnnotationData,
} from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import {
  getExternalAnnotationProviderName,
  getIElementTypeForBcfIntegrationType,
} from "@/components/ui/annotations/external-annotation-utils";
import { createAnnotationZip } from "@/modes/walk-mode/create-annotation-zip";
import { selectProjectIdForIntegrationType } from "@/store/integrations/integrations-selectors";
import { selectPanoAnnotationSection } from "@/store/selections-selectors";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { selectPanoAnnotationsAdjustedPose } from "@/utils/camera-transform";
import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import {
  IElementWithPose,
  selectAdvancedMarkupTemplateIds,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  GetTopicResponse,
  PostTopic,
  ProjectActions,
  createMutationAddCamView,
  createMutationAddExternalMarkup,
  createMutationAddLabel,
  createMutationAddMarkup,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { Matrix4, Mesh } from "three";
import {
  AnnotationCameraParameters,
  selectMutationAddCamViewData,
} from "../annotation-camview-utils";
import { createModel3dMutation } from "./create-model-3d-mutation";

/**
 * Defines the signature of the function for creation of Sphere XG  or external model 3d annotations.
 * The function returns a GUID of the created annotation or throw an error if the creation fails.
 */
type CreateModel3dAnnotation = (
  pano: IElementImg360,
  annotation: Mesh,
  details: AnnotationCreationData,
  cameraParams: AnnotationCameraParameters,
) => Promise<GUID>;

/** @returns a function to create a Sphere XG or External Model3d Annotation in a project */
export function useCreateModel3dAnnotation(): CreateModel3dAnnotation {
  const createSphereModel3dAnnotation = useCreateSphereXGModel3dAnnotation();
  const createExternalMarkupModel3dAnnotation =
    useCreateExternalModel3Annotation();

  return useCallback<CreateModel3dAnnotation>(
    (
      pano: IElementImg360,
      annotation: Mesh,
      details: AnnotationCreationData,
      cameraParams: AnnotationCameraParameters,
    ): Promise<GUID> => {
      if (isExternalAnnotationData(details)) {
        return createExternalMarkupModel3dAnnotation(pano, annotation, details);
      }

      return createSphereModel3dAnnotation(
        pano,
        annotation,
        details,
        cameraParams,
      );
    },
    [createSphereModel3dAnnotation, createExternalMarkupModel3dAnnotation],
  );
}

/**
 * Defines the signature of the function for creation of Sphere XG model 3d annotations.
 * The function returns a GUID of the created annotation or throw an error if the creation fails.
 */
type CreateSphereXGModel3dAnnotation = (
  pano: IElementImg360,
  annotation: Mesh,
  details: SphereXGAnnotationData,
  cameraParams: AnnotationCameraParameters,
) => Promise<GUID>;

function useCreateSphereXGModel3dAnnotation(): CreateSphereXGModel3dAnnotation {
  const projectApi = useCurrentProjectApiClient();
  const { coreApiClient } = useApiClientContext();
  const appStore = useAppStore();
  const uploadFile = useFileUploaderWithPromise();
  const dispatch = useAppDispatch();

  return useCallback<CreateSphereXGModel3dAnnotation>(
    (pano, annotation, details, cameraParams): Promise<GUID> => {
      const {
        title,
        assignee,
        description,
        dueDate,
        status,
        newAttachments,
        tags,
      } = details;
      async function createAnnotation(): Promise<GUID> {
        const appState = appStore.getState();
        // Pano annotations needs to be appended to the pano parent section
        const targetIElement = selectPanoAnnotationSection(pano)(appState);
        const targetOffset = new Matrix4().fromArray(
          selectPanoAnnotationsAdjustedPose(pano)(appState),
        );
        const projectId = selectProjectId(appState);
        const currentUser = selectCurrentUser(appState);

        assert(currentUser, "Expected a logged in user");
        assert(projectId, "Expected a project ID");
        assert(
          targetIElement,
          "Unable to compute the section where to place the new annotation",
        );

        const zipData = await createAnnotationZip(annotation);
        const { downloadUrl, md5 } = await uploadFile({
          file: new File([zipData], "model.zip", { type: "application/zip" }),
          coreApiClient,
          uploadElementType: UploadElementType.none,
          projectId,
          silent: true,
        });

        const model3dId = generateGUID();
        const markupId = generateGUID();

        const { add3dNodeMutation, worldMatrixInverse } = createModel3dMutation(
          {
            model: annotation,
            modelId: model3dId,
            name: title,
            modelUrl: downloadUrl,
            md5Hash: md5,
            targetOffset,
            targetIElement,
            fileSize: zipData.size,
            currentUserId: currentUser.id,
            appState,
          },
        );

        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          markupId,
          currentUserId: currentUser.id,
          rootId: targetIElement.rootId,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: targetIElement.rootId,
          name: title,
          description: description ?? "",
          annotationId: model3dId,
          markupFields,
        });

        const attachmentsMutations = createAttachments(
          targetIElement.rootId,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        const extras: IElementWithPose[] = add3dNodeMutation.group
          ? [add3dNodeMutation.group]
          : [];
        extras.push(add3dNodeMutation.newElement);
        const camViewMutationData = selectMutationAddCamViewData(
          cameraParams,
          model3dId,
          targetIElement.rootId,
          worldMatrixInverse,
          extras,
        )(appState);

        const camViewMutation = createMutationAddCamView(camViewMutationData);

        await projectApi.applyMutations([
          add3dNodeMutation,
          addMarkupMutation,
          ...attachmentsMutations,
          ...tagsMutations,
          camViewMutation,
        ]);

        // Update the IElement tree
        await dispatch(
          updateProject({
            projectApi,
            iElementQuery: {
              // We only need to fetch the subtree starting from the targetIElement
              ancestorIds: [targetIElement.id],
            },
          }),
        );

        return markupId;
      }

      return createAnnotation();
    },
    [appStore, coreApiClient, dispatch, projectApi, uploadFile],
  );
}

/**
 * Defines the signature of the function for creation of external 3d annotation.
 * The function returns a GUID of the created annotation or throw an error if the creation fails.
 */
type CreateExternalModel3dAnnotation = (
  pano: IElementImg360,
  annotation: Mesh,
  details: ExternalAnnotationData,
) => Promise<GUID>;

function useCreateExternalModel3Annotation(): CreateExternalModel3dAnnotation {
  const projectApi = useCurrentProjectApiClient();
  const { bcfServicesApiClient, coreApiClient } = useApiClientContext();
  const uploadFile = useFileUploaderWithPromise();
  const appStore = useAppStore();
  const dispatch = useAppDispatch();
  const viewContext = useViewRuntimeContext();

  return useCallback<CreateExternalModel3dAnnotation>(
    (pano, annotation, details): Promise<GUID> => {
      async function createAnnotation(): Promise<GUID> {
        const appState = appStore.getState();
        // Pano annotations needs to be appended to the pano parent section
        const targetIElement = selectPanoAnnotationSection(pano)(appState);
        assert(
          targetIElement,
          "Unable to compute the section where to place the new annotation",
        );
        const targetOffset = new Matrix4().fromArray(
          selectPanoAnnotationsAdjustedPose(pano)(appState),
        );
        const currentUser = selectCurrentUser(appState);
        assert(currentUser, "Expected a logged in user");

        const projectId = selectProjectId(appState);
        assert(projectId, "Expected a project ID");

        const zipData = await createAnnotationZip(annotation);
        const { downloadUrl, md5 } = await uploadFile({
          file: new File([zipData], "model.zip", { type: "application/zip" }),
          coreApiClient,
          uploadElementType: UploadElementType.none,
          projectId,
          silent: true,
        });

        const model3dId = generateGUID();
        const externalMarkupId = generateGUID();

        const deepLinkUrl = computeDeepLink(
          appState,
          viewContext,
          externalMarkupId,
        ).toString();

        const { externalAnnotationType } = details;
        const integrationProjectId = selectProjectIdForIntegrationType(
          externalAnnotationType,
        )(appState);
        assert(integrationProjectId, "Expected an integration project ID");
        const postTopic: PostTopic = createPostTopic({
          data: details,
          id: externalMarkupId,
          deepLinkUrl,
          providerName: getExternalAnnotationProviderName(
            externalAnnotationType,
          ),
        });

        let topicCreated = false;
        try {
          const getTopic: GetTopicResponse =
            await bcfServicesApiClient.createTopic(
              externalAnnotationType,
              integrationProjectId,
              postTopic,
            );
          topicCreated = !!getTopic.guid;

          const addExternalMarkupMutation = createMutationAddExternalMarkup({
            id: externalMarkupId,
            type: getIElementTypeForBcfIntegrationType(externalAnnotationType),
            externalIssueId: getTopic.guid,
            rootId: targetIElement.rootId,
            parentId: model3dId,
          });

          const { add3dNodeMutation } = createModel3dMutation({
            model: annotation,
            modelId: model3dId,
            name: getTopic.guid,
            modelUrl: downloadUrl,
            md5Hash: md5,
            targetOffset,
            targetIElement,
            fileSize: zipData.size,
            currentUserId: currentUser.id,
            appState,
          });

          await projectApi.applyMutations([
            add3dNodeMutation,
            addExternalMarkupMutation,
          ]);
        } catch (error) {
          // As there are many function that can throw an error, we are filtering here the errors that occurred when creating the topic
          if (topicCreated === false) {
            // We are verifying that the user had the right to create the annotation.
            // Right now the backend is not providing useful error messages, so we are performing a check here
            // to provide a better feedback to user.
            const externalProject = await bcfServicesApiClient.getProject(
              externalAnnotationType,
              integrationProjectId,
            );
            assert(
              externalProject,
              "Expected an external project to be defined",
            );
            if (
              externalProject.authorization.project_actions.includes(
                ProjectActions.createTopic,
              ) === false
            ) {
              throw new Error(
                `Missing permission to create a topic in the project: "${externalProject.name}"`,
              );
            }
          }

          throw error;
        }

        // Update the IElement tree
        await dispatch(
          updateProject({
            projectApi,
            iElementQuery: {
              // We only need to fetch the subtree starting from the targetIElement
              ancestorIds: [targetIElement.id],
            },
          }),
        );
        return externalMarkupId;
      }
      return createAnnotation();
    },
    [
      appStore,
      uploadFile,
      coreApiClient,
      viewContext,
      bcfServicesApiClient,
      projectApi,
      dispatch,
    ],
  );
}
