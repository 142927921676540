import { CameraSwitch } from "@/data-preparation-tool/ui/camera-switch";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { Box, Stack } from "@mui/material";
import { useActiveCamera } from "../r3f/cameras/active-camera-context";
import { CanvasToolbar } from "./canvas-toolbar";
import { ModeSwitch } from "./mode-switch";
import { ViewSettingsToolbar } from "./view-settings-toolbar";

/**
 * @returns Overlay to render for all modes
 */
export function AllModesOverlay(): JSX.Element {
  const hasOrthoProjectionOption = useAppSelector(
    selectHasFeature(Features.OrthoProjectionOption),
  );

  const currMode = useAppSelector(selectModeName);
  const { cameraProjection, setCameraProjection, projectionSwitchVisible } =
    useActiveCamera();

  const shouldShowProjectionOption =
    (currMode === "walk" || currMode === "overview") &&
    hasOrthoProjectionOption &&
    projectionSwitchVisible;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        p: 2,
      }}
    >
      <Stack direction="column" justifyContent="end" spacing="1em">
        {shouldShowProjectionOption && (
          <CameraSwitch
            cameraOptions3D={cameraProjection}
            onCameraChange={setCameraProjection}
          />
        )}
        <ModeSwitch />
      </Stack>

      <Stack
        direction="column"
        sx={{ height: "100%", py: 6 }}
        alignSelf="center"
      >
        <Stack direction="column" height="20%">
          <ViewSettingsToolbar />
        </Stack>
        <Stack direction="column" flexGrow={1} justifyContent="center">
          <CanvasToolbar />
        </Stack>
        {/** box used to ensure the CanvasToolbar is centered in the column */}
        <Box component="div" height="20%" />
      </Stack>
    </Stack>
  );
}
