import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { CloudToSheetAlignmentModeOverlay } from "./cloud-to-sheet-alignment-mode-overlay";
import { CloudToSheetAlignmentModeScene } from "./cloud-to-sheet-alignment-mode-scene";
export const cloudToSheetAlignmentMode: Mode = {
  name: "cloudToSheetAlignment",
  ModeScene: CloudToSheetAlignmentModeScene,
  ModeOverlay: CloudToSheetAlignmentModeOverlay,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align cloud to sheet",
    onBack({ dispatch }) {
      dispatch(changeMode("start"));
      return Promise.resolve();
    },
  },
};
