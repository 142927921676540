import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import {
  selectIsNewPin,
  selectPinMarker,
  setIsNewPin,
} from "@/store/pin-marker-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  PinColoredIcon,
} from "@faro-lotv/app-component-toolbox";
import Box from "@mui/material/Box";
import { Html } from "@react-three/drei";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { Vector3Tuple } from "three";
import { PinMarkerActionBar } from "./pin-marker-action-bar";

/** @returns pin icon with or without pin marker action bar */
export function PinMarkerRenderer(): JSX.Element | null {
  const pinContainer = useViewOverlayRef();
  const pinPosition = useAppSelector(selectPinMarker);

  const dispatch = useAppDispatch();

  const isNewPin = useAppSelector(selectIsNewPin);
  const [toolBarOpen, setToolBarOpen] = useState<boolean>(isNewPin);

  const onPinClick = useCallback(() => {
    setToolBarOpen(!toolBarOpen);
    dispatch(setIsNewPin(false));
  }, [dispatch, toolBarOpen]);

  useEffect(
    () => () => {
      dispatch(setIsNewPin(false));
    },
    [dispatch],
  );

  return pinPosition ? (
    <>
      {(toolBarOpen || isNewPin) && (
        <PinMarkerActionBar
          pinPosition={pinPosition}
          parentRef={pinContainer}
        />
      )}

      <PinRenderer
        position={pinPosition}
        parentRef={pinContainer}
        onClick={onPinClick}
      />
    </>
  ) : null;
}

/** Size, in pixels, of the icon used to render the pin marker */
const PIN_ICON_SIZE = 35;

type PinRendererProps = {
  /** marker position in world space */
  position: Vector3Tuple;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Callback when this icon is clicked */
  onClick(): void;
};

/** @returns the pin icon placed in 3D at given position */
function PinRenderer({
  position,
  parentRef,
  onClick,
}: PinRendererProps): JSX.Element {
  return (
    <Html
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.collapsed}
      eps={-1}
      position={position}
      portal={parentRef}
      style={{ pointerEvents: "auto", transform: "translateY(-75%)" }}
    >
      <Box
        aria-label="pin-marker"
        component="div"
        sx={{
          pointerEvents: "auto",
          opacity: 1,
          cursor: "pointer",
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          onClick();
        }}
      >
        <PinColoredIcon
          sx={{
            width: PIN_ICON_SIZE,
            height: PIN_ICON_SIZE,
          }}
        />
      </Box>
    </Html>
  );
}
