import { computeSplitScreenAlignment } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { useStoreActiveCameras } from "@/components/common/view-runtime-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { PointCloudSimpleSubscene } from "@/components/r3f/effects/point-cloud-simple-subscene";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { PointCloudObject, SheetObject } from "@/object-cache";
import { isFromSheetObject } from "@/object-cache-type-guard";
import {
  selectAnchorPositionsForCloudToSheetAlignment,
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import {
  setAlignedElementAnchor1ForCloudToSheetAlignment,
  setAlignedElementAnchor2ForCloudToSheetAlignment,
  setIncrementalTransformForCloudToSheetAlignment,
  setReferenceElementAnchor1ForCloudToSheetAlignment,
  setReferenceElementAnchor2ForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  AnchorPairPlacement,
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  View,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Vector3Tuple } from "three";
import {
  useCenterCameraOnBoundingBoxIn2D,
  useClippingPlanesAtCloudElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
} from "../alignment-modes-commons/align-to-cad-utils";
import { useOverlayElements } from "../overlay-elements-context";

type CloudToSheetSplitScreenSceneProps = {
  /** The reference sheet object for alignment */
  sheetObject: SheetObject;

  /** The aligned cloud object for alignment */
  cloudObject: PointCloudObject;
};

/** @returns the split-screen scene for align cloud-to-sheet in 2d */
export function CloudToSheetSplitScreenScene({
  sheetObject,
  cloudObject,
}: CloudToSheetSplitScreenSceneProps): JSX.Element {
  const { firstScreen, secondScreen } = useOverlayElements();
  const background = useThree((s) => s.scene.background);
  // first camera is for top or left screen, second is for bottom or right screen
  const firstCamera = useNewOrthographicCamera();
  const secondCamera = useNewOrthographicCamera();

  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );

  const cloudBox = useObjectBoundingBox(cloudObject, cloudObject.iElement.id);
  assert(cloudBox, "unable to compute bounding box for cloud");

  const cloudCenteringData = useCenterCameraOnBoundingBoxIn2D(
    cloudBox,
    firstScreen ? firstScreen.clientWidth / firstScreen.clientHeight : 1,
  );

  const sheetElements = useMemo(() => [sheetObject.iElement], [sheetObject]);
  const sheetCenteringData = useCenterCameraOnPlaceholders({
    sheetElements,
    activeSheetFor2DNavigation: sheetObject.iElement,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: secondScreen
      ? secondScreen.clientWidth / secondScreen.clientHeight
      : 1,
  });

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(firstCamera, cloudCenteringData);
    centerOrthoCamera(secondCamera, sheetCenteringData);
  }, []);

  const cameras = useMemo(
    () => [firstCamera, secondCamera],
    [firstCamera, secondCamera],
  );

  useStoreActiveCameras(cameras);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );
  const clippingPlanes = useClippingPlanesAtCloudElevation(
    sheetElevation,
    isCrossSectionEnabled,
  );

  useCloudForAlignmentView(cloudObject, clippingPlanes);

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const dispatch = useAppDispatch();
  const alignmentAnchorPositions = useAppSelector(
    selectAnchorPositionsForCloudToSheetAlignment,
  );

  const changeSheetAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor1ForCloudToSheetAlignment(position));
    },
    [dispatch],
  );
  const changeSheetAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor2ForCloudToSheetAlignment(position));
    },
    [dispatch],
  );
  const changeCloudAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setAlignedElementAnchor1ForCloudToSheetAlignment(position));
    },
    [dispatch],
  );
  const changeCloudAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setAlignedElementAnchor2ForCloudToSheetAlignment(position));
    },
    [dispatch],
  );

  // compute split screen alignment when there is change in anchor positions
  useEffect(() => {
    const alignmentTransform = computeSplitScreenAlignment(
      alignmentAnchorPositions,
      false,
    );
    if (alignmentTransform) {
      dispatch(
        setIncrementalTransformForCloudToSheetAlignment(alignmentTransform),
      );
    }
  }, [alignmentAnchorPositions, dispatch]);

  return (
    <>
      <View
        camera={firstCamera}
        trackingElement={firstScreen}
        background={background}
        hasSeparateScene
      >
        <ambientLight intensity={1.0} />
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.movingElementAnchor1}
          anchor2Position={alignmentAnchorPositions.movingElementAnchor2}
          changeAnchor1Position={changeCloudAnchor1Position}
          changeAnchor2Position={changeCloudAnchor2Position}
        >
          <PointCloudRenderer pointCloud={cloudObject} />
        </AnchorPairPlacement>
        <EffectPipelineWithSubScenes>
          <PointCloudSimpleSubscene pointCloud={cloudObject} />
          <FilteredRenderPass
            filter={(o) => !isObjPointCloudPoint(o)}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipelineWithSubScenes>
        <SheetModeControls
          camera={firstCamera}
          referencePlaneHeight={sheetElevation}
          enabled={controlsEnabled}
        />
      </View>
      <View
        camera={secondCamera}
        trackingElement={secondScreen}
        background={background}
        hasSeparateScene
      >
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={alignmentAnchorPositions.referenceElementAnchor1}
          anchor2Position={alignmentAnchorPositions.referenceElementAnchor2}
          changeAnchor1Position={changeSheetAnchor1Position}
          changeAnchor2Position={changeSheetAnchor2Position}
        >
          <SheetRenderer sheet={sheetObject} />
        </AnchorPairPlacement>
        <EffectPipeline>
          <FilteredRenderPass filter={(o) => isFromSheetObject(o)} />
          <DesaturatePass />
          <FilteredRenderPass
            filter={(o) => !isFromSheetObject(o)}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipeline>
        <SheetModeControls
          camera={secondCamera}
          referencePlaneHeight={sheetElevation}
          enabled={controlsEnabled}
        />
      </View>
    </>
  );
}
