import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectCadSvfMetadata } from "@/store/cad/cad-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroTooltip,
  InfoIcon,
  selectIElement,
  selectIElementProjectApiLocalPose,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert, GUID } from "@faro-lotv/foundation";
import { isIElementBimModelSection } from "@faro-lotv/ielement-types";
import {
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
} from "@faro-lotv/service-wires";
import { FormControlLabel, Icon, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Matrix4 } from "three";
import {
  combineDirectConversion,
  getAllModelTransformations,
} from "../../cad-model-tree/cad-metadata-utility";

export type ChangeCadCsDialogProps = {
  /* true to display the dialog, false to keep it hidden */
  open: boolean;

  /** GUID of the Model being edited */
  idIElementModel3dStream: GUID;

  /** callback to be called when user close/cancel the dialog */
  onClose(): void;
};

/**
 * @returns component displaying a dialog asking user to change the coordinates system used for CAD
 */
export function ChangeCadCsDialog({
  open,
  idIElementModel3dStream,
  onClose,
}: ChangeCadCsDialogProps): JSX.Element | null {
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const [transformInProgress, setTransformInProgress] = useState(false);

  // Which orientation system to apply to the Cad; undefined = no choice
  const [userChoiceOrientation, setUserChoiceOrientation] =
    useState<OrientationType>();

  // Which origin to apply to the Cad; undefined = no choice
  const [userChoiceOrigin, setUserChoiceOrigin] = useState<OriginType>();

  const metadata = useAppSelector(selectCadSvfMetadata);

  // pre compute transformations for each case
  const transformationsMatrix = useMemo(
    () => (metadata ? getAllModelTransformations(metadata) : undefined),
    [metadata],
  );

  /**
   * @returns the transformation to apply on the model according to user's choice; undefined if the user choice is invalid
   * @param orientationType the orientation to use
   */
  const getMeshTransformation = useCallback(
    (orientationType: OrientationType | undefined): Matrix4 | undefined => {
      switch (orientationType) {
        case OrientationType.useModelTrueNorth:
          return transformationsMatrix?.toModelTrueNorth;
        case OrientationType.useProjectNorth:
          return transformationsMatrix?.toModelNorth;
        case OrientationType.useOriginalImport:
          return new Matrix4().identity();
      }
    },
    [transformationsMatrix],
  );

  /**
   * @returns the new absolute transformation to apply to the CAD; undefined if the user choice is invalid
   * @param orientationType the orientation to use
   * @param originType the origin to use
   */
  const getNewCadTransformation = useCallback(
    (
      orientationType: OrientationType | undefined,
      originType: OriginType | undefined,
    ): Matrix4 | undefined => {
      const rotationMatrixFromModelToUserChoice =
        getMeshTransformation(orientationType);
      if (!transformationsMatrix || !rotationMatrixFromModelToUserChoice) {
        return undefined;
      }
      if (
        originType === OriginType.useSurveyPoint &&
        transformationsMatrix.refPointInMeshCs
      ) {
        // generate transformation matrix translating origin of mesh to Ref Point
        const meshToRefPointTranslation = new Matrix4().makeTranslation(
          transformationsMatrix.refPointInMeshCs.clone().negate(),
        );
        // combine rotation and translation
        return combineDirectConversion(
          rotationMatrixFromModelToUserChoice,
          meshToRefPointTranslation,
        );
      }
      // return optional rotation without offset
      return rotationMatrixFromModelToUserChoice;
    },
    [getMeshTransformation, transformationsMatrix],
  );

  /**
   * Apply a new transformation to the CAD.
   *
   * @param orientationType the orientation to use
   * @param originType the origin to use
   */
  const applyChangeCadCs = useCallback(
    async (
      orientationType: OrientationType | undefined,
      originType: OriginType | undefined,
    ): Promise<void> => {
      const model3DStreamElement = selectIElement(idIElementModel3dStream)(
        store.getState(),
      );
      assert(model3DStreamElement, "Invalid CAD stream");
      const bimModelSectionElement = selectIElement(
        model3DStreamElement.parentId,
      )(store.getState());
      assert(
        bimModelSectionElement &&
          isIElementBimModelSection(bimModelSectionElement),
        "Invalid 3D Model Element in applyTransformationToCad",
      );

      // transformation to apply to mesh (from exported mesh to new location of mesh)
      const relativeTransformMesh =
        getNewCadTransformation(orientationType, originType) ?? new Matrix4();

      const mutationTransform = selectIElementProjectApiLocalPose(
        bimModelSectionElement,
        relativeTransformMesh,
      )(store.getState());

      setTransformInProgress(true);

      // apply the mutation updating the CAD transformation
      try {
        const mutations = [
          createMutationSetElementPosition(
            bimModelSectionElement.id,
            mutationTransform.pos,
          ),
          createMutationSetElementRotation(
            bimModelSectionElement.id,
            mutationTransform.rot,
          ),
          createMutationSetElementScale(bimModelSectionElement.id, {
            x: 1,
            y: 1,
            z: 1,
          }),
        ];

        await projectApi.applyMutations(mutations);

        // Update the IElement tree
        await dispatch(
          updateProject({
            projectApi,
            iElementQuery: {
              // We only need to fetch the subtree starting from the modified element
              ancestorIds: [bimModelSectionElement.id],
            },
          }),
        );

        openToast({
          title: "3D model transformation successfully applied",
          variant: "success",
        });
      } catch (error) {
        handleErrorWithToast({
          title: "Failed to save new 3D model transformation",
          error,
        });
      }

      setTransformInProgress(false);
    },
    [
      handleErrorWithToast,
      projectApi,
      store,
      openToast,
      dispatch,
      idIElementModel3dStream,
      getNewCadTransformation,
    ],
  );

  const noUserSelection = useMemo(
    () => userChoiceOrientation === undefined || userChoiceOrigin === undefined,
    [userChoiceOrientation, userChoiceOrigin],
  );

  const okTooltip = useMemo(() => {
    if (noUserSelection) return "Select an origin and orientation";
  }, [noUserSelection]);

  return (
    <FaroDialog
      title="Model Coordinates"
      open={open}
      onConfirm={() =>
        applyChangeCadCs(userChoiceOrientation, userChoiceOrigin)
      }
      onClose={onClose}
      onCancel={onClose}
      isConfirmDisabled={noUserSelection}
      confirmText="Apply"
      confirmTooltip={okTooltip}
      showXButton
      showSpinner={transformInProgress}
    >
      <Stack gap={3}>
        <Typography>
          Select which coordinate system should be used for the model
        </Typography>
        <FaroRadioGroup
          onChange={(v) => {
            setUserChoiceOrigin(stringToOriginType(v.target.value));
          }}
          label={
            <Stack direction="row" alignItems="center">
              ORIGIN
              <FaroTooltip title="Define the origin of the model">
                <Icon
                  sx={[
                    {
                      [">:first-of-type"]: {
                        // Font size will affect the image container size, it's set to 0 so that the container it's the size of the image
                        fontSize: 0,
                        width: "1rem",
                        height: "1rem",
                        m: 0.5,
                      },
                    },
                  ]}
                >
                  <InfoIcon />
                </Icon>
              </FaroTooltip>
            </Stack>
          }
        >
          <RadioButton
            value={OriginType.useInternalOrigin}
            label="Internal Origin (Default)"
            disabled={false}
          />
          <FaroTooltip title="Not supported yet. Coming soon…">
            <RadioButton
              value={OriginType.useProjectBasePoint}
              disabled
              label="Project Base Point"
            />
          </FaroTooltip>
          <FaroTooltip
            title={
              metadata?.RefPointTransform
                ? undefined
                : "Not available in this model"
            }
          >
            <RadioButton
              value={OriginType.useSurveyPoint}
              disabled={!metadata?.RefPointTransform}
              label="Survey Point"
            />
          </FaroTooltip>
        </FaroRadioGroup>
        <FaroRadioGroup
          label={
            <Stack direction="row" alignItems="center">
              ORIENTATION
              <FaroTooltip title="Define the North direction of the model">
                <Icon
                  sx={[
                    {
                      [">:first-of-type"]: {
                        // Font size will affect the image container size, it's set to 0 so that the container it's the size of the image
                        fontSize: 0,
                        width: "1rem",
                        height: "1rem",
                        m: 0.5,
                      },
                    },
                  ]}
                >
                  <InfoIcon />
                </Icon>
              </FaroTooltip>
            </Stack>
          }
          onChange={(v) => {
            setUserChoiceOrientation(stringToOrientationType(v.target.value));
          }}
        >
          <RadioButton
            value={OrientationType.useOriginalImport}
            label="Default Orientation"
            disabled={false}
          />
          <FaroTooltip
            title={
              metadata?.NorthVectorInModelCS
                ? undefined
                : "Not available in this model"
            }
          >
            <RadioButton
              value={OrientationType.useProjectNorth}
              disabled={!metadata?.NorthVectorInModelCS}
              label="Project North"
            />
          </FaroTooltip>
          <FaroTooltip
            title={
              metadata?.TrueNorthVectorInModelCS
                ? undefined
                : "Not available in this model"
            }
          >
            <RadioButton
              value={OrientationType.useModelTrueNorth}
              disabled={!metadata?.TrueNorthVectorInModelCS}
              label="True North"
            />
          </FaroTooltip>
        </FaroRadioGroup>
      </Stack>
    </FaroDialog>
  );
}

type RadioButtonProps = {
  // Label displayed after the radio button
  label: string;

  // Value associated with the radio button (also used as aria-label)
  value: string;

  // true to disable the radio button
  disabled: boolean;
};

/**
 * @returns the FormControlLabel embedding the radio button used for each orientation choice
 */
function RadioButton({
  label,
  value,
  disabled,
}: RadioButtonProps): JSX.Element {
  return (
    <FormControlLabel
      value={value}
      control={<FaroRadio />}
      disabled={disabled}
      label={label}
      aria-label={value}
      sx={{ m: 0 }}
    />
  );
}

// List of possible CS origin.
// Take note that the CS store in the IElement is not the same one shown to the user
// (e.g. Z is up from user point of view, but Y is up in the IElement)
enum OriginType {
  useInternalOrigin = "useInternalOrigin",
  useProjectBasePoint = "useProjectBasePoint",
  useSurveyPoint = "useSurveyPoint",
}

// List of possible CS orientation.
// Take note that the CS store in the IElement is not the same one shown to the user
// (e.g. Z is up from user point of view, but Y is up in the IElement)
enum OrientationType {
  useModelTrueNorth = "useModelTrueNorth",
  useProjectNorth = "useProjectNorth",
  useOriginalImport = "useOriginalImport",
}

// Convert the string associated with one of the radio button to the associated OriginType
function stringToOriginType(s: string): OriginType | undefined {
  switch (s) {
    case OriginType.useInternalOrigin:
      return OriginType.useInternalOrigin;
    case OriginType.useProjectBasePoint:
      return OriginType.useProjectBasePoint;
    case OriginType.useSurveyPoint:
      return OriginType.useSurveyPoint;
  }
}

// Convert the string associated with one of the radio button to the associated OrientationType
function stringToOrientationType(s: string): OrientationType | undefined {
  switch (s) {
    case OrientationType.useModelTrueNorth:
      return OrientationType.useModelTrueNorth;
    case OrientationType.useProjectNorth:
      return OrientationType.useProjectNorth;
    case OrientationType.useOriginalImport:
      return OrientationType.useOriginalImport;
  }
}
