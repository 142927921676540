import { GUID } from "@faro-lotv/foundation";
import { create } from "zustand";

type TagsManagementContext = {
  /** The id of the current active area */
  activeAreaId: GUID | undefined;
  /** A callback to set the active area id in the context */
  setActiveAreaId(id: GUID | undefined): void;
};

export const useTagsManagementContext = create<TagsManagementContext>()(
  (set) => ({
    activeAreaId: undefined,
    setActiveAreaId: (id: GUID | undefined) => set({ activeAreaId: id }),
  }),
);
