import { PointCloudObject } from "@/object-cache";
import { isPointCloudObject, UnknownObject } from "@/object-cache-type-guard";
import { selectActiveAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { ThreeEvent } from "@react-three/fiber";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { AnnotationPicker } from "./annotation-picker/annotation-picker";
import { MultiPointMeasures } from "./multi-point-measures/multi-point-measures";
import { PointCloudAnalysisLabelTool } from "./point-cloud-analysis-label-tool";
import { PointCloudAnalysisTool } from "./point-cloud-analysis-tool";
import { ToolControlsRef } from "./tool-controls-interface";

/** Parameters for onActiveToolChanged. */
export type ActiveToolAndPicking = {
  /** Active tool, or null when no active tool. */
  activeTool: ToolName | null;

  /** True if the active tool requires picking. */
  isPickingToolActive: boolean;
};

/** An object with callbacks to interact with the tools */
export type PickingToolsRef = {
  /** Forward model hovered event to the active tool */
  onModelHovered(ev: ThreeEvent<PointerEvent>, iElementId: string): void;

  /** Forward model click events to the active tool */
  onModelClicked(ev: ThreeEvent<MouseEvent>, iElementId: string): void;

  /** Forward model zoom events to the active tool */
  onModelZoomed(ev: ThreeEvent<MouseEvent>, iElementId: string): void;
};

export type PickingToolsProps = {
  /** The current active model */
  activeModels: UnknownObject[] | null;

  /**
   * Enable/Disable the measurement controls
   *
   * @default true
   */
  enableControls?: boolean;

  /**
   * Callback to signal that active tool has changed.
   *
   * @param activeToolAndPicking new active tool and picking
   */
  onActiveToolChanged?(activeToolAndPicking: ActiveToolAndPicking): void;
};

/**
 * @returns True if the event was triggered on a valid element
 * @param activeModels The list of valid models
 * @param iElementId The id of the element from which the event was generated
 */
function isEventOnActiveModels(
  activeModels: UnknownObject[] | null,
  iElementId: string,
): boolean {
  return (
    !!activeModels && !!activeModels.find((e) => e.iElement.id === iElementId)
  );
}

export const PickingTools = forwardRef<PickingToolsRef, PickingToolsProps>(
  function PickingTools(
    { activeModels, enableControls, onActiveToolChanged },
    ref,
  ): JSX.Element {
    // Forward events to the active tool
    const measurementToolRef = useRef<ToolControlsRef>(null);
    const annotationPickerRef = useRef<ToolControlsRef>(null);
    const pointCloudAnalysisToolRef = useRef<ToolControlsRef>(null);

    const toolRefMap = useMemo(
      () =>
        new Map([
          [ToolName.annotation, annotationPickerRef],
          [ToolName.measurement, measurementToolRef],
          [ToolName.analysis, pointCloudAnalysisToolRef],
          // Analysis label tool does not need `ref`, still need to keep in
          // the map to have disable exploration controls behaviour
          [ToolName.analysisLabel, null],
        ]),
      [],
    );

    // Notify if a tool is active to disable exploration controls click to focus
    const activeTool = useAppSelector(selectActiveTool);
    const isPickingToolActive =
      activeTool !== null && toolRefMap.has(activeTool);
    useEffect(() => {
      onActiveToolChanged?.({ activeTool, isPickingToolActive });
    }, [activeTool, isPickingToolActive, onActiveToolChanged]);

    useImperativeHandle(ref, () => ({
      onModelClicked(ev, iElementId) {
        if (ev.delta > 1 || !activeTool) return;
        if (!isEventOnActiveModels(activeModels, iElementId)) {
          return;
        }
        const toolRef = toolRefMap.get(activeTool);
        if (toolRef?.current?.pointClicked) {
          toolRef.current.pointClicked(ev, iElementId);
        }
      },
      onModelHovered(ev, iElementId) {
        if (!activeTool) return;
        const toolRef = toolRefMap.get(activeTool);
        if (!isEventOnActiveModels(activeModels, iElementId)) {
          return;
        }
        if (toolRef?.current?.pointHovered) {
          toolRef.current.pointHovered(ev, iElementId);
        }
      },
      onModelZoomed(ev, iElementId) {
        if (!activeTool) return;
        const toolRef = toolRefMap.get(activeTool);
        if (!isEventOnActiveModels(activeModels, iElementId)) {
          return;
        }
        if (toolRef?.current?.pointZoomed) {
          toolRef.current.pointZoomed(ev, iElementId);
        }
      },
    }));

    const activeModelsIds = useMemo(
      () => activeModels?.map((e) => e.iElement.id),
      [activeModels],
    );

    const activePointCloud = useMemo((): PointCloudObject | undefined => {
      const pointCloud = activeModels?.find((e) => isPointCloudObject(e));
      if (pointCloud && isPointCloudObject(pointCloud)) {
        return pointCloud;
      }
    }, [activeModels]);

    const activeAnalysis = useAppSelector(selectActiveAnalysis);

    return (
      <>
        {activeModelsIds && (
          <MultiPointMeasures
            enableControls={enableControls}
            activePointCloud={activePointCloud}
            ref={measurementToolRef}
          />
        )}
        <AnnotationPicker
          isActive={activeTool === ToolName.annotation}
          activeModels={activeModels}
          ref={annotationPickerRef}
        />
        {activePointCloud && (
          <PointCloudAnalysisTool
            activePointCloud={activePointCloud}
            ref={pointCloudAnalysisToolRef}
          />
        )}
        {activePointCloud && activeAnalysis && (
          <PointCloudAnalysisLabelTool
            isActive={activeTool === ToolName.analysisLabel}
            activeAnalysis={activeAnalysis}
            activePointCloud={activePointCloud}
          />
        )}
      </>
    );
  },
);
