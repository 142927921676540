import { SplitDirection } from "@/store/modes/alignment-ui-types";
import {
  selectAlignedElementIdForCloudToSheetAlignment,
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
  selectReferenceElementIdForCloudToSheetAlignment,
  selectSplitDirectionForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import {
  setCrossSectionEnabledForCloudToSheetAlignment,
  setSplitDirectionForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import {
  AlignmentModeSplitScreenOverlay,
  CrossSectionProps,
} from "../alignment-modes-commons/alignment-mode-split-screen-overlay";

/** @returns The cloud-to-sheet alignment split screen overlay*/
export function CloudToSheetAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSplitDirectionForCloudToSheetAlignment,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setSplitDirectionForCloudToSheetAlignment(newDirection));
  }, [dispatch, splitDirection]);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );
  const elevation = useAppSelector(selectElevationForCloudToSheetAlignment);

  const cloudCrossSectionProps: CrossSectionProps = useMemo(
    () => ({
      enabled: isCrossSectionEnabled,
      elevation,
      onToggleCrossSection: () => {
        dispatch(
          setCrossSectionEnabledForCloudToSheetAlignment(
            !isCrossSectionEnabled,
          ),
        );
      },
    }),
    [dispatch, isCrossSectionEnabled, elevation],
  );

  const pointCloudSectionId = useAppSelector(
    selectAlignedElementIdForCloudToSheetAlignment,
  );
  const pointCloudSection = useAppSelector(selectIElement(pointCloudSectionId));
  assert(
    pointCloudSection && isIElementSectionDataSession(pointCloudSection),
    "Cloud-to-sheet alignment mode requires a valid cloud as aligned element",
  );

  const areaId = useAppSelector(
    selectReferenceElementIdForCloudToSheetAlignment,
  );

  const area = useAppSelector(selectIElement(areaId));

  assert(
    area && isIElementAreaSection(area),
    "Cloud-to-sheet alignment mode requires a valid area as a reference element",
  );

  return (
    <AlignmentModeSplitScreenOverlay
      splitDirection={splitDirection}
      onChangeSplitDirection={changeSplitDirection}
      firstScreenCrossSection={cloudCrossSectionProps}
      alignedElement={pointCloudSection}
      referenceElement={area}
    />
  );
}
