import {
  ElementIcon,
  ElementIconType,
  useElementIcon,
} from "@/components/ui/icons";
import {
  TREE_NODE_HEIGHT,
  TreeNode,
  TreeNodeProps,
} from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroText,
  NO_TRANSLATE_CLASS,
  NoTranslate,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { selectIElement, TreeData } from "@faro-lotv/project-source";
import { Box, Stack } from "@mui/system";
import { isEqual } from "es-toolkit";
import { Tree } from "react-arborist";
import { TreeProps } from "react-arborist/dist/module/types/tree-props";
import { TagsManagementScene } from "./tags-management-selector";

/** The fake id used to identify the dataset folder in the tree */
const DATA_SESSION_FOLDER_ID = "data-session-folder-id";

/** The fake id used to identify the 360s folder in the tree */
const PANO_FOLDER_ID = "pano-folder-id";

type TagsManagementTreeProps = {
  /** The list of elements that make up the scene for the current selected area */
  currentScene: TagsManagementScene;
  /** A flag specifying if the scene is still loading */
  isLoading: boolean;
};

/** @returns The project tree shown in the tags management mode */
export function TagsManagementTree({
  currentScene,
  isLoading,
}: TagsManagementTreeProps): JSX.Element | null {
  return (
    <TreeWrapper>
      <TagsManagementTreeBase
        currentScene={currentScene}
        isLoading={isLoading}
      />
    </TreeWrapper>
  );
}

/** The custom data of the nodes of the tree */
type TagsManagementTreeData = Omit<TreeData, "children"> & {
  /** The icon type of the node */
  icon?: ElementIconType;
  /** The children nodes of the node */
  children: TagsManagementTreeData[] | null;
};

/** @returns The tree showing the list of scans and 360s available in the current scene */
function TagsManagementTreeBase({
  height,
  currentScene,
  isLoading,
}: Pick<TreeProps<TagsManagementTreeData>, "height"> &
  TagsManagementTreeProps): JSX.Element | null {
  const tree = useAppSelector(selectTagsManagementTree(currentScene), isEqual);

  if (isLoading) {
    return null;
  }

  if (!tree.length) {
    return (
      <FaroText variant="heading12">
        No 360° or scans found in this area.
      </FaroText>
    );
  }

  return (
    <NoTranslate sx={{ height: "100%" }}>
      <Tree<TagsManagementTreeData>
        data={tree}
        openByDefault={false}
        disableDrag
        disableDrop
        width="100%"
        rowHeight={TREE_NODE_HEIGHT}
        indent={18}
        height={height}
      >
        {TagsManagementTreeNode}
      </Tree>
    </NoTranslate>
  );
}

/**
 * @returns The component to pick the right UI element based on the tree node type
 */
function TagsManagementTreeNode({
  node,
  style,
}: TreeNodeProps<TagsManagementTreeData>): JSX.Element {
  if (node.id === DATA_SESSION_FOLDER_ID || node.id === PANO_FOLDER_ID) {
    return (
      <FaroText
        variant="heading12"
        className={NO_TRANSLATE_CLASS}
        // The lineHeight is slightly increased to keep the section header
        // closer to the first element of the list
        sx={{ ...style, lineHeight: `${TREE_NODE_HEIGHT + 8}px` }}
      >
        {node.data.label}
      </FaroText>
    );
  }

  return <TagsManagementTreeIElementNode node={node} style={style} />;
}

/**
 * @returns The UI component used to render the scans and 360s in the tree
 */
function TagsManagementTreeIElementNode({
  node,
  style,
}: TreeNodeProps<TagsManagementTreeData>): JSX.Element {
  const icon = useElementIcon(node.data.element, node.data.directParent);

  return (
    <Box component="div" style={{ ...style, height: "100%" }}>
      <TreeNode<TagsManagementTreeData>
        node={node}
        collapseIcon
        isSelectable={false}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ pr: "4px", overflow: "auto" }}
          gap="6px"
        >
          <ElementIcon
            icon={node.data.icon ?? icon}
            sx={{ fontSize: "1.125em" }}
          />
          <TruncatedFaroText
            variant="bodyM"
            tooltip={<NoTranslate>{node.data.label}</NoTranslate>}
          >
            {node.data.label}
          </TruncatedFaroText>
        </Stack>
      </TreeNode>
    </Box>
  );
}

/**
 * @returns A selector to collect the list of nodes of tree
 * @param currentScene The current scene elements
 */
function selectTagsManagementTree(currentScene: TagsManagementScene) {
  return (state: RootState) => {
    const nodes: TagsManagementTreeData[] = [];

    // Collect scans
    const dataSessionsIds = Object.keys(currentScene.scans);
    if (dataSessionsIds.length > 0) {
      nodes.push({
        id: DATA_SESSION_FOLDER_ID,
        label: "Datasets",
        children: null,
      });

      for (const dataSessionId of dataSessionsIds) {
        const dataSession = selectIElement(dataSessionId)(state);
        if (!dataSession) continue;

        const node: TagsManagementTreeData = {
          id: dataSession.id,
          label: dataSession.name,
          element: dataSession,
          children: [],
          directParent: dataSession.parentId
            ? selectIElement(dataSession.parentId)(state)
            : undefined,
        };
        nodes.push(node);

        for (const scan of currentScene.scans[dataSessionId]) {
          node.children?.push({
            id: scan.id,
            label: scan.name,
            element: scan,
            children: null,
            directParent: scan.parentId
              ? selectIElement(scan.parentId)(state)
              : undefined,
            icon: ElementIconType.ScanIcon,
          });
        }
        node.children?.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" }),
        );
      }
    }

    // Collect rooms
    if (currentScene.rooms.length > 0) {
      nodes.push({
        id: PANO_FOLDER_ID,
        label: "360° Photo",
        children: null,
      });

      for (const room of currentScene.rooms) {
        nodes.push({
          id: room.id,
          label: room.name,
          element: room,
          children: null,
          directParent: room.parentId
            ? selectIElement(room.parentId)(state)
            : undefined,
          icon: ElementIconType.PanoramaIcon,
        });
      }
    }

    return nodes;
  };
}
