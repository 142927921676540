import { useStoreActiveCameras } from "@/components/common/view-runtime-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useCached3DObject } from "@/object-cache";
import {
  selectControlPointsAlignmentAnchorPositions,
  selectElementToAlignWithControlPointsAlignment,
} from "@/store/modes/control-points-alignment-mode-selectors";
import {
  setControlPointsMovingElementAnchor1,
  setControlPointsMovingElementAnchor2,
} from "@/store/modes/control-points-alignment-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  AnchorPairPlacement,
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  FilteredRenderPass,
  State,
  View,
  selectChildDepthFirst,
  selectIElement,
  useNonExhaustiveEffect,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericImgSheet,
  IElementImg360,
  isIElementAreaSection,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useMemo, useState } from "react";
import { Vector3Tuple } from "three";
import { useNewOrthographicCamera } from "../alignment-modes-commons/align-to-cad-utils";
import { useOverlayElements } from "../overlay-elements-context";
import { areTwoPointsForAlignmentTooClose } from "./control-points-alignment-set-points-panel";

/**
 * Finding fist sheet in parent area for aligned element.
 * In case if aligned element is a layer, return itself
 *
 * @param state The app state
 * @param elementToAlign element to be aligned
 * @returns selected sheet or fist sheet in selected area
 */
function getSheetToAlign(
  state: State,
  elementToAlign: IElement,
): IElementGenericImgSheet {
  assert(
    isIElementAreaSection(elementToAlign) ||
      isIElementGenericImgSheet(elementToAlign),
    "invalid type of element used for ControlPoints alignment",
  );

  if (isIElementGenericImgSheet(elementToAlign)) return elementToAlign;

  const firstSheet = selectChildDepthFirst(
    elementToAlign,
    isIElementGenericImgSheet,
  )(state);

  assert(
    firstSheet && isIElementGenericImgSheet(firstSheet),
    "Selected area doesn't contain any sheet ",
  );

  return firstSheet;
}

/** @returns The overlay for the control-points alignment mode */
export function ControlPointsAlignmentModeScene(): JSX.Element {
  const dispatch = useAppDispatch();
  const { singleScreen } = useOverlayElements();
  const store = useAppStore();
  const { openToast } = useToast();

  const background = useThree((s) => s.scene.background);
  const camera = useNewOrthographicCamera();

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const alignmentAnchorPositions = useAppSelector(
    selectControlPointsAlignmentAnchorPositions,
  );

  const elementToAlignId = useAppSelector(
    selectElementToAlignWithControlPointsAlignment,
  );
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  assert(elementToAlign, "Element to align not assigned");

  const activeSheet = getSheetToAlign(store.getState(), elementToAlign);

  const sheet = useCached3DObject(activeSheet);

  const validateAndStoreAnchorsPositions = useCallback(
    (
      isFirstAnchorPoint: boolean,
      position?: Vector3Tuple,
      otherPoint?: Vector3Tuple,
    ) => {
      dispatch(
        isFirstAnchorPoint
          ? setControlPointsMovingElementAnchor1(position)
          : setControlPointsMovingElementAnchor2(position),
      );

      const arePointsTooClose = areTwoPointsForAlignmentTooClose(
        position,
        otherPoint,
      );

      if (arePointsTooClose) {
        openToast({
          title: "Second control point too close to the first one",
          message:
            "Please select a point that is farther away to ensure accurate alignment",
          variant: "warning",
          persist: false,
          onClose: () => {
            dispatch(
              isFirstAnchorPoint
                ? setControlPointsMovingElementAnchor1(undefined)
                : setControlPointsMovingElementAnchor2(undefined),
            );
          },
        });
      }
    },
    [dispatch, openToast],
  );

  const changeSheetAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      validateAndStoreAnchorsPositions(
        true,
        position,
        alignmentAnchorPositions.movingElementAnchor2,
      );
    },
    [
      alignmentAnchorPositions.movingElementAnchor2,
      validateAndStoreAnchorsPositions,
    ],
  );

  const changeSheetAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      validateAndStoreAnchorsPositions(
        false,
        position,
        alignmentAnchorPositions.movingElementAnchor1,
      );
    },
    [
      alignmentAnchorPositions.movingElementAnchor1,
      validateAndStoreAnchorsPositions,
    ],
  );

  const sheetElements = useMemo(() => [activeSheet], [activeSheet]);

  const sheetCenteringData = useCenterCameraOnPlaceholders({
    sheetElements,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: singleScreen
      ? singleScreen.clientWidth / singleScreen.clientHeight
      : 1,
  });

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(camera, sheetCenteringData);
  }, []);

  const cameras = useMemo(() => [camera], [camera]);

  useStoreActiveCameras(cameras);

  return (
    <View
      camera={camera}
      trackingElement={singleScreen}
      background={background}
      hasSeparateScene
    >
      <AnchorPairPlacement
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        anchor1Position={alignmentAnchorPositions.movingElementAnchor1}
        anchor2Position={alignmentAnchorPositions.movingElementAnchor2}
        changeAnchor1Position={changeSheetAnchor1Position}
        changeAnchor2Position={changeSheetAnchor2Position}
      >
        <SheetRenderer sheet={sheet} />
      </AnchorPairPlacement>
      <EffectPipeline>
        {/* NOTE: overview maps can not be realigned. It's prohibited from layers selector UI.
          So for ControlPoints alignment activeSheet never will be overview map.
          Only imported by user sheets will be rendered in that view.*/}
        <FilteredRenderPass filter={(o) => o.name === activeSheet.id} />
        <DesaturatePass />
        <FilteredRenderPass
          filter={(o) => o.name !== activeSheet.id}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipeline>
      <SheetModeControls camera={camera} enabled={controlsEnabled} />
    </View>
  );
}
