import { FaroRadio, FaroRadioGroup, FaroText } from "@faro-lotv/flat-ui";
import { assert, validateEnumValue } from "@faro-lotv/foundation";
import { FloorplanGenerationMeasurementUnit } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/system";
import { useState } from "react";

type GenerateFloorplanDialogProps = {
  /** The initial state of the measurement unit. */
  initialMeasurementUnit: FloorplanGenerationMeasurementUnit;

  /** Callback when the user selects a different measurement unit. */
  onMeasurementUnitChanged(newUnit: FloorplanGenerationMeasurementUnit): void;
};

/** @returns Dialog contents for when the user has sufficient token funds for a floor plan generation. */
export function GenerateFloorPlanDialog({
  initialMeasurementUnit,
  onMeasurementUnitChanged,
}: GenerateFloorplanDialogProps): JSX.Element {
  const [measurementUnit, setMeasurementUnit] = useState(
    initialMeasurementUnit,
  );

  // TODO: Implement token display and proper content for dialog https://faro01.atlassian.net/browse/SWEB-6371
  return (
    <Stack gap={2}>
      <FaroText variant="bodyM">
        Generating a floor plan incurs a backend cost. Please confirm if you
        want to proceed.
      </FaroText>
      <Box component="div">
        <FaroRadioGroup
          label="Unit of measure"
          value={measurementUnit}
          onChange={(ev) => {
            const newUnit = ev.target.value;
            assert(
              validateEnumValue(newUnit, FloorplanGenerationMeasurementUnit),
              "invalid measurement unit",
            );
            setMeasurementUnit(newUnit);
            onMeasurementUnitChanged(newUnit);
          }}
        >
          <FaroRadio
            value={FloorplanGenerationMeasurementUnit.metric}
            label="Metric"
          />
          <FaroRadio
            value={FloorplanGenerationMeasurementUnit.imperial}
            label="Imperial"
          />
          <FaroRadio
            value={FloorplanGenerationMeasurementUnit.both}
            label="Both"
          />
        </FaroRadioGroup>
      </Box>
    </Stack>
  );
}
