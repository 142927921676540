import { EventType } from "@/analytics/analytics-events";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectIsControlPointLayerAlignment,
  selectWizardElementToAlignId,
  selectWizardPointCloudStreamToAlignId,
  selectWizardReferenceElementId,
  selectWizardReferencePointCloudStreamId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { resetAlignmentWizard } from "@/store/modes/alignment-wizard-mode-slice";
import {
  AlignmentReference,
  setAlignmentReference,
  setCloudForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import {
  setAlignedElementIdForCloudToSheetAlignment,
  setReferenceElementIdForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { setElementToAlignByControlPointsAlignment } from "@/store/modes/control-points-alignment-mode-slice";
import {
  setAlignedLayerId,
  setReferenceLayerId,
} from "@/store/modes/layer-to-layer-alignment-mode-slice";
import {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  setReferenceCloudForAlignment,
  setSheetIdForAlignment,
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  redirectToAlignmentTool,
  redirectToRegistrationTool,
} from "@/utils/redirects";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGenericModel3d,
  isIElementModel3dStream,
  isIElementSectionDataSession,
  isIElementSectionWithTypeHint,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
  selectProjectId,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { useCallback } from "react";
import { AlignWizardProgressBar } from "./align-wizard-progress-bar";
import { AlignWizardSplitScreen } from "./align-wizard-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function AlignWizardModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();

  const projectId = useAppSelector(selectProjectId);
  assert(projectId, "invalid number project id");

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const pointCloudStreamToAlignId = useAppSelector(
    selectWizardPointCloudStreamToAlignId,
  );
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const referencePointCloudStreamId = useAppSelector(
    selectWizardReferencePointCloudStreamId,
  );
  const alignedElement = useAppSelector(selectIElement(elementToAlignId));
  const isControlPointLayerAlignment = useAppSelector(
    selectIsControlPointLayerAlignment,
  );

  const isNewCloudToSheetAlignment = useAppSelector(
    selectHasFeature(Features.NewCloudToSheetAlignment),
  );

  const selectAndRunAlignment = useCallback(() => {
    if (!alignedElement) return;

    if (
      isControlPointLayerAlignment &&
      (isIElementAreaSection(alignedElement) ||
        isIElementGenericImgSheet(alignedElement))
    ) {
      dispatch(setElementToAlignByControlPointsAlignment(alignedElement.id));
      dispatch(changeMode("controlPointsAlignment"));

      Analytics.track(
        isIElementAreaSection(alignedElement)
          ? EventType.openControlPointsAlignmentArea
          : EventType.openControlPointsAlignmentLayer,
      );

      return;
    }

    const referenceElement = selectIElement(referenceElementId)(
      store.getState(),
    );
    if (!referenceElement) return;

    if (isIElementAreaSection(alignedElement)) {
      assert(
        isIElementGenericModel3d(referenceElement) ||
          isIElementSectionDataSession(referenceElement),
        "invalid element type",
      );

      const sheet = selectChildDepthFirst(
        alignedElement,
        isIElementGenericImgSheet,
      )(store.getState());
      assert(sheet, "Sheet not found for selected floor.");

      if (isIElementGenericModel3d(referenceElement)) {
        // sheet to cad alignment
        dispatch(
          setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation),
        );
        dispatch(setSheetForCadAlignment(sheet.id));
        dispatch(changeMode("sheetToCadAlignment"));
      } else {
        // sheet to cloud alignment
        const cloud = selectIElement(referencePointCloudStreamId)(
          store.getState(),
        );
        assert(
          cloud,
          "Sheet-to-cloud alignment should not be called when there is no point cloud selected",
        );

        dispatch(
          setStepForSheetToCloudAlignment(
            SheetToCloudAlignmentStep.setElevation,
          ),
        );
        dispatch(setSheetIdForAlignment(sheet.id));
        dispatch(setReferenceCloudForAlignment(cloud.id));
        dispatch(changeMode("sheetToCloudAlignment"));
      }
    } else if (isIElementGenericImgSheet(alignedElement)) {
      assert(
        elementToAlignId &&
          referenceElementId &&
          isIElementGenericImgSheet(referenceElement),
        "invalid element type",
      );
      dispatch(setAlignedLayerId(elementToAlignId));
      dispatch(setReferenceLayerId(referenceElementId));
      dispatch(changeMode("layerToLayerAlignment"));
    } else if (isIElementSectionDataSession(alignedElement)) {
      const pointCloudStreamToAlign = selectIElement(pointCloudStreamToAlignId)(
        store.getState(),
      );
      if (isIElementAreaSection(referenceElement)) {
        if (isNewCloudToSheetAlignment) {
          dispatch(
            setAlignedElementIdForCloudToSheetAlignment(alignedElement.id),
          );
          dispatch(
            setReferenceElementIdForCloudToSheetAlignment(referenceElement.id),
          );
          dispatch(changeMode("cloudToSheetAlignment"));
        } else {
          redirectToAlignmentTool({
            projectId,
            elementId: pointCloudStreamToAlign?.id ?? alignedElement.id,
            floorId: referenceElement.id,
            state: store.getState(),
            dispatch,
          });
        }
      } else if (isIElementSectionDataSession(referenceElement)) {
        Analytics.track(EventType.startPairwiseRegistrationWorkflow);

        redirectToRegistrationTool({
          projectId,
          pointCloudId1: referenceElement.id,
          pointCloudId2: alignedElement.id,
        });
      } else if (isIElementModel3dStream(referenceElement)) {
        const cloud = selectIElement(pointCloudStreamToAlignId)(
          store.getState(),
        );

        assert(
          cloud,
          "Cloud-To-Cad alignment should not be called when there is no point cloud selected",
        );

        dispatch(setAlignmentReference(AlignmentReference.bimModel));
        dispatch(setCloudForCloudAlignment(cloud.id));
        dispatch(changeMode("cloudToCadAlignment"));
      } else {
        throw new Error(
          "Invalid combination of reference and aligned elements in Alignment Wizard",
        );
      }
    } else if (isIElementModel3dStream(alignedElement)) {
      const cloud = selectIElement(referencePointCloudStreamId)(
        store.getState(),
      );

      assert(
        cloud,
        "Cad-To-Cloud alignment should not be called when there is no point cloud selected",
      );
      dispatch(setAlignmentReference(AlignmentReference.pointCloud));
      dispatch(setCloudForCloudAlignment(cloud.id));
      dispatch(changeMode("cloudToCadAlignment"));
    } else if (
      isIElementSectionWithTypeHint(
        alignedElement,
        IElementTypeHint.dataSetFocus,
      ) ||
      isIElementSectionWithTypeHint(alignedElement, IElementTypeHint.dataSetEls)
    ) {
      const cloud = selectIElement(pointCloudStreamToAlignId)(store.getState());

      assert(
        cloud,
        "Cloud-To-Cad alignment should not be called when there is no point cloud selected",
      );
      dispatch(setAlignmentReference(AlignmentReference.bimModel));
      dispatch(setCloudForCloudAlignment(cloud.id));
      dispatch(changeMode("cloudToCadAlignment"));
    }
    dispatch(resetAlignmentWizard());
  }, [
    alignedElement,
    isControlPointLayerAlignment,
    referenceElementId,
    dispatch,
    referencePointCloudStreamId,
    elementToAlignId,
    pointCloudStreamToAlignId,
    isNewCloudToSheetAlignment,
    projectId,
  ]);

  return (
    <Stack
      direction="column"
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <AlignWizardProgressBar goToNextStep={selectAndRunAlignment} />
      <AlignWizardSplitScreen />
    </Stack>
  );
}
