import { PointCloudObject } from "@/object-cache";
import { getLotvMath, selectPointsInSphere } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import {
  CircleGeometry,
  Group,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Sphere,
  Vector3,
} from "three";

type PreviewPlaneRendererProps = {
  // Position of the plane
  position: Vector3;
  // Point cloud object to use compute the plane orientation
  pointcloud: PointCloudObject;
};

// cached temporary objects for avoiding repleated allocation
const sphere = new Sphere();
const normal = new Vector3(0, 0, 1);
const zAxis = new Vector3(0, 0, 1);
const refDir = new Vector3();

/** Name assigned to the preview plane object in the scene graph */
export const PREVIEW_PLANE_NAME = "PreviewPlane";

/**
 * @returns Renderer for a planar disc aligned with the point cloud normal direction at a position
 */
export function PreviewPlaneRenderer({
  position,
  pointcloud,
}: PreviewPlaneRendererProps): JSX.Element | null {
  const { camera } = useThree();
  // Set preview planar disc radius to 0.5 meters
  const radius = 0.5;
  const color = 0xffff00;

  const [plane] = useState<Object3D>(() => {
    const geom = new CircleGeometry(radius, 32);
    const material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.4,
    });
    const mesh = new Mesh(geom, material);
    mesh.name = PREVIEW_PLANE_NAME;
    return mesh;
  });

  const groupRef = useRef<Group>(null);

  useEffect(() => {
    async function updatePlane(): Promise<void> {
      if (!groupRef.current) return;

      // Estimate normal direction by bestfit neighbourhood points within 0.1 meters radius
      const selectionRadius = 0.1;
      sphere.set(position, selectionRadius);
      const selection = selectPointsInSphere(pointcloud, {
        sphere,
        maxNumberOfPoints: 1000,
      });
      if (!selection) return;

      const lotvMath = await getLotvMath();
      const fitResult = lotvMath.fitPlane(selection.points);
      if (!fitResult) return;

      normal.set(fitResult.normal.x, fitResult.normal.y, fitResult.normal.z);

      // Use direction from the position to the camera position as reference to
      // make sure the normal is always pointing towards the camera
      camera.getWorldPosition(refDir);
      refDir.sub(position);
      if (refDir.dot(normal) < 0) {
        normal.negate();
      }
      // offset the plane 1 inch along normal, so it won't be blocked by points
      const offset = 0.0254;
      groupRef.current.position.copy(position);
      groupRef.current.position.addScaledVector(normal, offset);

      groupRef.current.quaternion.setFromUnitVectors(zAxis, normal);
      groupRef.current.updateWorldMatrix(true, true);
    }
    updatePlane().catch(console.error);
  }, [camera, pointcloud, position]);

  return (
    <group ref={groupRef}>
      <primitive object={plane} />
    </group>
  );
}
