import { PointCloudObject } from "@/object-cache";
import { selectElevationForCloudToSheetAlignment } from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { View } from "@faro-lotv/app-component-toolbox";
import { useThree } from "@react-three/fiber";
import { useCallback, useState } from "react";
import { PerspectiveCamera } from "three";
import { useCameraInCurrentScene } from "../alignment-modes-commons/align-to-cad-utils";
import { CloudElevationScene } from "../alignment-modes-commons/cloud-elevation-scene";
import { useOverlayElements } from "../overlay-elements-context";
import { setElevationForCloudToSheetAlignment } from "@/store/modes/cloud-to-sheet-alignment-mode-slice";

type CloudToSheetElevationSceneSceneProps = {
  /** The reference cloud object for alignment */
  cloudObject: PointCloudObject;
};

/** @returns The sheet elevation view scene */
export function CloudToSheetElevationScene({
  cloudObject,
}: CloudToSheetElevationSceneSceneProps): JSX.Element {
  const { singleScreen } = useOverlayElements();
  const background = useThree((s) => s.scene.background);

  const [camera] = useState<PerspectiveCamera>(() => new PerspectiveCamera());
  useCameraInCurrentScene(camera);

  const dispatch = useAppDispatch();

  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );

  const updateSheetElevation = useCallback(
    (elevation: number) => {
      dispatch(setElevationForCloudToSheetAlignment(elevation));
    },
    [dispatch],
  );

  return (
    <View
      camera={camera}
      trackingElement={singleScreen}
      background={background}
      hasSeparateScene
    >
      <CloudElevationScene
        pointCloud={cloudObject}
        cloudElevation={sheetElevation}
        updateCloudElevation={updateSheetElevation}
        camera={camera}
      />
    </View>
  );
}
